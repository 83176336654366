export const TransactionData = {
    history: [
        {
            "action": "Deposit",
            "date": "12/10/2024",
            "time": "12:00:12 AM",
            "UTR": 45865412,
            "topupAmount": 500,
            "closingBalance": 500
        },
        {
            "action": "Withdraw",
            "date": "12/10/2024",
            "time": "12:00:12 AM",
            "UTR": 45865412,
            "topupAmount": 500,
            "closingBalance": 0
        },
        {
            "action": "Refernal",
            "date": "12/10/2024",
            "time": "12:00:12 AM",
            "UTR": 45865412,
            "topupAmount": 500,
            "closingBalance": 0
        },
        {
            "action": "Win",
            "date": "12/10/2024",
            "time": "12:00:12 AM",
            "UTR": 45865412,
            "topupAmount": 500,
            "closingBalance": 0
        }
    ]
}