export const HistoryData = {
    history: [
        {
            "gameType": "2 Tokens",
            "date": "12/10/2024",
            "opponentName": "ghtf864",
            "roomCode": 45865412,
            "time": "12:00:12 AM",
            "winloose": 5,
            "winStaus": true,
            "closingBalance": 500
        },
        {
            "gameType": "2 Tokens",
            "date": "12/10/2024",
            "roomCode": 45865412,
            "opponentName": "ghtf864",
            "time": "12:00:12 AM",
            "winloose": 5,
            "winStaus": false,
            "closingBalance": 500
        },
        {
            "gameType": "3 Tokens",
            "date": "12/10/2024",
            "roomCode": 45865412,
            "opponentName": "ghtf864",
            "time": "12:00:12 AM",
            "winloose": 5,
            "winStaus": true,
            "closingBalance": 500
        },
        {
            "gameType": "Classic",
            "date": "12/10/2024",
            "time": "12:00:12 AM",
            "roomCode": 45865412,
            "opponentName": "ghtf864",
            "winloose": 5,
            "winStaus": true,
            "closingBalance": 500
        },
        {
            "gameType": "Classic",
            "date": "12/10/2024",
            "time": "12:00:12 AM",
            "opponentName": "ghtf864",
            "roomCode": 45865412,
            "winloose": 5,
            "winStaus": false,
            "closingBalance": 500
        }
    ]
}