import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const userSideBarOptions = [
    {
        title: 'Play',
        path: '/'
    },
    {
        title: 'My Profile',
        path: '/profile'
    },
    {
        title:' My Wallet',
        path: 'mywallet'
    },
    {
        title: 'Game History',
        path: 'game-hisotry'
    },
    {
        title: 'Transaction History',
        path: 'transaction-history'
    },
    {
        title: 'Refer & Earn',
        path: 'refer-earn'
    },
    {
        title: 'Notification',
        path: 'notification'
    },
    {
        title: 'Support',
        path: 'support'
    },
    {
        title: 'Legal Terms',
        path: 'legal'
    }

]

const guestUserSideBarOptions = [
    'Play',
    'Register',
    'Support',
    'Legal Terms'
];

const Sidebar = () => {

    const dispatch = useDispatch();

    const hideSidebar = () => {
        dispatch({type: 'toggle'})
    }

    return (
        <div className='SideBar'>
            <div className='overlay' onClick={hideSidebar}></div>

            <div className='sidebar-container'>
            {
                userSideBarOptions.map((option, index) => {
                    return (
                    <Link key={index} className='menuOptions' onClick={hideSidebar}  to={option.path}>{option.title}</Link>
                    )
                })

            }
            </div>
        </div>
    )
}

export default Sidebar;