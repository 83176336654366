import React from 'react'
import { Nav, Tab } from 'react-bootstrap';
import { TransactionFilters } from '../Data/transactionHistoryFilter';
import { TransactionData } from '../Data/transactionData';
import TransactionHistoryCard from '../Components/TransactionHistoryCard';

export default function TransactionHistory() {

  let historyData = null;

  return (
    <div className='transaction-history-page'>
   
    <div className='history-search'>
        <input type="text" placeholder='Enter UTR code' />
    </div>

      <Tab.Container id="left-tabs-example" defaultActiveKey={TransactionFilters[0]}>
            <Nav variant="pills" className="flex-row">
              {
                TransactionFilters.map((filter, index) =>{
                  return(
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={filter}>{filter}</Nav.Link>
                    </Nav.Item>
                  )
                  
                })
              }
            </Nav>
            <Tab.Content>
            {
                TransactionFilters.map((filter, index) =>{
                  if(filter === "All")
                    historyData = TransactionData.history;
                  else
                    historyData = TransactionData.history.filter((e => e.action === filter))
                  return(      
                      <Tab.Pane eventKey={filter} key={index}>
                        <TransactionHistoryCard data={historyData}/>
                      </Tab.Pane>
                  )

                }
            )}   
            </Tab.Content>  

      </Tab.Container>


    </div>
  )
}
