import ReactDOM from "react-dom/client";
import React from "react";
import App from "./src/App";
import './assets/scss/app.scss';
import { Provider } from "react-redux";
import { store } from "./src/Redux/Store/store";

const root = ReactDOM.createRoot(document.querySelector('.body-wrapper'));
root.render(
<Provider store={store}>
    <App/>
</Provider>
)