import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'

export default function Footer() {

  return (
    <div className='footer'>
        <div className='footer-container'>
            <Link to={'/'} className='icon'>
                <img src='assets/images/Home.png' />
                <span>Home</span>
            </Link>
            <Link to={'/refer-earn'} className='icon'>
                <img src=' assets/images/referral.png' />
                <span>Refer</span>
            </Link>
            <Link to={'/mywallet'} className='icon'>
                <img src=' assets/images/wallet.png' />
                <span>Wallet</span>
            </Link>
            <Link to={'/profile'} className='icon'>
                <img src=' assets/images/profile.png' />
                <span>Profile</span>
            </Link>
        </div>
        
    </div>
  )
}
