import React from 'react'

const MyWallet = () => {
  return (
    <div className='my-wallet'>
        <div className='wallet-list'>
            <div className='deposit-cash wallet'>
                <div className='wallet-header'>
                    <div className='left-content'>
                        <div className='icon'>
                            <img src=' assets/images/money.png' />
                            <span className='amount'>100</span>
                        </div>
                        <div className='wallet-title'>Deposit Cash</div>
                    </div>
                    <div className='right-content'>
                       <div className='add-cash button'>Add Cash</div>
                    </div>
                </div>
                <div className='wallet-body'>
                    Can be used to play Tournaments & Battles. <br/>
                    Cannot be withdrawn to Paytm or Bank.
                </div>
            </div>

            <div className='winning-cash wallet'>
            <div className='wallet-header'>
                    <div className='left-content'>
                        <div className='icon'>
                            <img src=' assets/images/money.png' />
                            <span className='amount'>100</span>
                        </div>
                        <div className='wallet-title'>Winning Cash</div>
                    </div>
                    <div className='right-content'>
                       <div className='withdraw button'>Withdraw</div>
                    </div>

                </div>

                <div className='wallet-body'>
                Can be withdrawn to Paytm or Bank. <br/>
                Can be used to play Tournaments & Battles.
                </div>
            </div>

            <div className='cashback wallet'>
            <div className='wallet-header'>
                    <div className='left-content'>
                        <div className='icon'>
                            <img src=' assets/images/money.png' />
                            <span className='amount'>100</span>
                        </div>
                        <div className='wallet-title'>Cashback</div>
                    </div> 
                    <div className='right-content'>
                       <div className='play-games button'>Play Games</div>
                    </div>                  
                </div>

                <div className='wallet-body'>
                    Can be used to play Tournaments & Battles. <br/>
                    Cannot be withdrawn to Paytm or Bank.
                </div>  
            </div>

        </div>
    </div>
  )
}

export default MyWallet;
