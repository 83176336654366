import React, { useEffect, useState }  from 'react'
import {Tables} from '../Data/Tables'
import { useLocation, useNavigate } from "react-router-dom";
import {RoomDetails} from '../Components/RoomDetails';
import { Button } from 'react-bootstrap';
import CreateBattleComponent from '../Components/CreateBattleComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export const Room = () => {
    const location = useLocation();
    const gameId = Number(location.state.gameId);
    const [gameDataState, setGameData] = useState();
    let navigate = useNavigate();

    useEffect(() => {
       let gamesData = Tables.games.find(games => games.GameID === gameId);
       setGameData(gamesData);
    },[gameDataState])

    function backToGamePlay(){
        navigate('/');
    }

    return (
        <div className='rooms'>
            <div className='btnBack btn'>
                <Button variant="danger" onClick={backToGamePlay}>Back</Button>
            </div>

            <Tabs defaultActiveKey="customBattles"
                    id="uncontrolled-tab-example"
                    className="mb-3">

                <Tab eventKey="customBattles" title="Custom Battles" className='battle-tabs'>
                        <CreateBattleComponent />
                    </Tab>

                    <Tab eventKey="fixedBattles" title="Fixed Games">
                        <div className='room-info'>
                        {
                            gameDataState && gameDataState.Rooms.map((room, index) => {
                                return (
                                    <RoomDetails 
                                        rooms={room}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </div>
                    </Tab>
            </Tabs>
        </div>
    )
}
