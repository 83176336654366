import Button from 'react-bootstrap/Button';
import React from 'react'

export const KYCPending = () => {
  return (
    <div className='KYCPending-Component'>
        <div className='kyc-inner-container'>
            <div className='kyc-text'>KYC is pending  <span className='alert-icon'>!</span></div>
            <div className='complete-button button'>
            <Button variant="success">Complete Now</Button>
            </div>
        </div>
    </div>
  )
}
