import React from 'react'

export default function Support() {
  return (
    <div className='support-page'>
      <div className='support-container'>
        <div className='icon'> 
          <img src=' assets/images/support.png' />
        </div>

        <div className='contact-options'>
              <div className='title'>Contact Us on below</div>

              <div className='options'>
                <div className='icon1 icon button'>
                  <img src=' assets/images/whatsapp.png'/>
                </div>

                <div className='icon2 icon button'>
                  <img src=' assets/images/facebook.png'/>
                </div>

                <div className='icon3 icon button'>
                  <img src=' assets/images/insta.png'/>
                </div>

              </div>
        </div>

      </div>
    </div>
  )
}
