import React from 'react'
import { OpenBattelsData } from '../Data/OpenBattelsData'
import OpenBattels from './OpenBattle'

export default function CreateBattleComponent() {
  return (
      <div className='battle-info'>
          <div className="create-battle-section">
              <div className="battle-header title">Create a Battle</div>
                  <div className="battle-amount-section">
                      <input type="text" placeholder="Amount"/>
                      <button type="button">Set</button>
              </div>
          </div>
          <div className='open-battles-header'>
            <div>
              <img src='assets/images/swords.png' />
              <div className='title'>Open Battles</div>
              </div>
            <div className='btnRules button'>
              <div className='title'>Rules</div>
              <img src='assets/images/info.png'/>
            </div>
          </div>
          <div className='open-battles'>
            {
              OpenBattelsData.challenges.map((battleInfo, index) => {
                return(
                  <div className='battle' key={index}>
                    <OpenBattels data={battleInfo}/>

                    </div>
                )
              })
            }
          </div>
        </div>
  )
}
