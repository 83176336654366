import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import History from '../Components/History';
import { HistoryData } from '../Data/historyData';
import { HistoryFilters } from '../Data/historyFilter';


export default function GameHistory() {

  let historyData = null;

  return (
    <div className='history-page'>
   
    <div className='history-search'>
        <input type="text" placeholder='Enter Room code' />
    </div>

      <Tab.Container id="left-tabs-example" defaultActiveKey={HistoryFilters[0]}>
            <Nav variant="pills" className="flex-row">
              {
                HistoryFilters.map((filter, index) =>{
                  return(
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={filter}>{filter}</Nav.Link>
                    </Nav.Item>
                  )
                  
                })
              }
            </Nav>
            <Tab.Content>
            {
                HistoryFilters.map((filter, index) =>{
                  if(filter === "All")
                    historyData = HistoryData.history;
                  else
                    historyData = HistoryData.history.filter((e => e.gameType === filter))
                  return(      
                      <Tab.Pane eventKey={filter} key={index}>
                        <History data={historyData}/>
                      </Tab.Pane>
                  )

                }
            )}   
            </Tab.Content>  

      </Tab.Container>


    </div>
  )
}
