import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import {legalData} from '../Data/legalData';

console.log(legalData);

export const Legal = () => {
  return (
    <div className='legal-terms'>
         <Accordion>
            {
                legalData.options.map((data, index) => {
                    return(
                        <Accordion.Item key={index} eventKey={index}> 
                        {console.log(data.title)}
                        <Accordion.Header>{data.title}</Accordion.Header>
                        <Accordion.Body>
                            {data.content}
                        </Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
           
         </Accordion>
    </div>
  )
}
