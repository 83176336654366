import React, {useRef, useState} from 'react';
import { Utils } from '../Utils/Util';
const Config = require("../Config/Config.json")


console.log(Config);

function LoginPage() {
    const [otpSent, setOTP] = useState(false);
    const button = useRef();
    function checkForNum(evt){
        evt.target.value = evt.target.value.replace(/[^\d]+/g, ''); 
    }

    function sendOtp(){
        Utils.postRequest(Config["OTP_URL"], null, () => {
            console.log('callac');
            setOTP(true);
        });
    }

    function enableButton(evt){
        if(evt.target.value.length >= 9){
            button.current.classList.remove('disable');
        }else{
            button.current.classList.add('disable');
        }
    }

  return (
    <div className='login-page'>
        <div className='login-container'>
            <div className='login-header'> 
             <div className='login-title'> Login/Signup </div>
            </div>
            <div className='login-body'>
                <form className='login-form'>
                <div className='username'>
                    <input type="text" onKeyDown={enableButton} onChange={checkForNum} className='mobile field' placeholder='Mobile Number' maxLength={10}/>
                </div>

               { 
                    otpSent &&
                    <div className='otp-container'>
                        <input type="text" className='otp field' placeholder='OTP'/>
                    </div>
                }

                </form>
            </div>

            <div className='login-footer'>
            
                <div className='loginButton'>
                    <div className='btnLogin disable button' ref={button} onClick={sendOtp}>Get Otp</div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default LoginPage;