import React from 'react'

export default function TransactionHistoryCard(props) {
  return (
        <div>
            {props.data.map((content, index) => {
        return(
           <div className="transaction-history-card" key={index}> 
                <div className='history-header'>{content.action} </div>
                <div className='history-body'>
                    <div className='history-time'>
                        <span>{content.date}</span>
                        <span>{content.time}</span>
                    </div>

                    {
                        <div className={'history-name'}>
                        
                            <div className={(content.action === "Withdraw" ? 'withdraw' : 'deposit') + ' winStatus'}>
                            {content.action === "withdrawl" ?  "Payment Withdrawl of" : "Payment Desposit of" }  {content.topupAmount}
                            </div>
                            <div>{content.roomCode}</div>
                         </div>
                    }

                    {
                        content.gameType === "Wallet" &&
                        <div className={'history-name'}>
                        
                            <div className={'Balance Topup'}>
                            {content.action === "Withdraw" ?  "withdraw" : "desposit" } Through UPI 
                            </div>
                            <div>{content.UTR}</div>
                         </div>
                         
                    }

                    <div className='history-amount'>
                        <div className={(content.action === "Withdraw" ? "withdraw" : 'deposit') + ' amount'}>
                            {content.topupAmount}
                        </div>
                        <div className='closing-balance'>closing balance {content.closingBalance}</div>
                    </div>
                </div>
            </div>
        );
    })}
    </div>
  )
}
