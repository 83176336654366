import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import ContentRender from './ContentRender'
import { useSelector } from 'react-redux';
import Header from '../Components/Header';
import Footer from './Footer';

export default function LandingPage() {
    let sideBarOpen = useSelector(state => state.SidebarReducer.open);
    const [promptEvent, setPromptEvent] = useState(null);
    const [isAppInstalled, setAppInstalled]= useState(false);

    useEffect(() => {
      window.addEventListener('beforeinstallprompt', handleInstallPrompt);

      return () => {
          window.addEventListener('beforeinstallprompt', handleInstallPrompt)
      }
  }, [isAppInstalled]);

  const handleInstallPrompt = (evt) => {
      evt.preventDefault();
      setPromptEvent(evt);
      setAppInstalled(window.matchMedia("(display-mode: standalone)").matches);
  };
  
  const onInstallPromptHandlar = () => {
    if(promptEvent)
        promptEvent.prompt();

        promptEvent.userChoice.then((result) => {
          setAppInstalled(result.outcome === "accepted");
        });
  }

  return (
    <div>
        <Header onPromptHandlar={onInstallPromptHandlar} isAppInstalled={isAppInstalled} />

        <div className="page-body-wrapper">
                {sideBarOpen && <Sidebar />}
                <ContentRender />
                <Footer />
        </div>
    </div>
  )
}
