export const OpenBattelsData = {
    "challenges" : [
        {
            "challenger": "xyz",
            "entryFee": 100,
            "pool": 190
        },
        {
            "challenger": "pqr",
            "entryFee": 100,
            "pool": 190
        },
        {
            "challenger": "abc",
            "entryFee": 100,
            "pool": 190
        },
        {
            "challenger": "ghj",
            "entryFee": 100,
            "pool": 190
        },
        {
            "challenger": "ry",
            "entryFee": 100,
            "pool": 190
        }
    ]
}