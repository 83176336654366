import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

export default function Advertise() {

  return (
    <div className='advertise-page'>
        <Carousel>
            <Carousel.Item interval={1500}>
                <img className="d-block w-100" src="assets/images/game-icon.jpg" />

                <Carousel.Caption>
                    <h5>First slide label</h5>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className="d-block w-100" src='assets/images/game-icon.jpg' />
                <Carousel.Caption>
                    <h5>First slide label</h5>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>

    </div>
  )
}
