import React from "react";
import {GamesData} from '../Data/gameData';
import { useNavigate } from "react-router-dom";
import GameThumbnail from "../Components/GameThumbnail";
import Advertise from "../Components/Advertise";

const GamePlay = () => {
    const navigate = useNavigate();

    function redirectToGameRooms(evt){
        let btnID = evt.currentTarget.getAttribute('gameid');
        navigate('/rooms', { state: {gameId: btnID}})
    }

    return (
        <div className="game-play-page">

            <div className="game-play-container">
                <div className="header">
                    <div className="instruction">Don't close the games once dice are open or it will be consider as loose.</div>
                </div>
                <div className="game-play-body">
                    <div className="games-container">
                        {GamesData.games.map((game, index) => {
                            return(
                               <GameThumbnail key={index} className={game.name} data={game} onClick={redirectToGameRooms}/>
                            )
                        })}
                    </div>
                </div>
            </div>

            <Advertise />
            
        </div>
    )
}

export default GamePlay;