import React from 'react'

export default function WalletIcon() {
  return (
    <div className='wallet-icon button'>
        <div className='wallet'>
            <img src='images/money.png'/>
            <div className='cash-title'>
                <span>cash</span>
                <span>10</span>
            </div>

            <div className='plus-icon'>+</div>
        </div>
    </div>
  )
}
