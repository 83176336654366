import axios from "axios"

export const Utils = {
    postRequest(url, data, callback) {
        axios.post(url, data)
        .then(({data}) => {
            if (data["StatusID"] === 1)
                callback()
        })
    },

    getRequest(url, data, callback) {
        axios.get(url, data)
            .then(({data}) => {
                console.log(data, data["status"] === 1);
                if (data["StatusID"] === 1)
                    callback()
            });
    }
}