import React from 'react';
import ButtonComponent from './Button';

const GameThumbnail = (props) => {
    return(
        <div className={props.className + " game-thumnail button"} onClick={props.onClick}>
             <div className='header'>
                <div className="gameName title">
                    {props.data.name}
                </div>
                <div className="game-range">
                    <img src="assets/images/rupee.png"/> 
                {props.data.range}
                </div>
             </div>
             <div className='body'>
                <img src="assets/images/game-icon.jpg" />
             </div>
             <div className='footer'>
                <ButtonComponent buttonText="Play Now" className="btnPlayNow" />
             </div>
                
        </div>
    )
}

export default GameThumbnail;