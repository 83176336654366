export const Tables = {
    games: [
        {
            "GameID": 1,
            "Rooms": [
                {
                    "Amount": 5,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 10,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 30,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 40,
                    "CurrentlyPlaying": 200
                }
            ]
        },
        {
            "GameID": 2,
            "Rooms": [
                {
                    "Amount": 50,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 60,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 70,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 80,
                    "CurrentlyPlaying": 200
                }
            ]
        },
        {
            "GameID": 3,
            "Rooms": [
                {
                    "Amount": 100,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 150,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 200,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 250,
                    "CurrentlyPlaying": 200
                }
            ]
        },
        {
            "GameID": 4,
            "Rooms": [
                {
                    "Amount": 1000,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 1500,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 2000,
                    "CurrentlyPlaying": 200
                },
                {
                    "Amount": 2500,
                    "CurrentlyPlaying": 200
                }
            ]
        }
    ]
}