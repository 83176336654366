import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import WalletIcon from './WalletIcon';
import {useNavigate } from 'react-router-dom';
import ButtonComponent from './Button';

const Header = ({onPromptHandlar, isAppInstalled}) => {

    const [isLogin, setLogin] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

   

    const onLoginClicked = () => {
        navigate('/login')
    }

    return(
        <div className='Header-Container'>
            <div className='menuButton button' onClick={(e) => dispatch({type: 'toggle'})}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="game-title">
                <img src=" assets/images/logo.png" />
            </div>

            <div className='right-content'>
                {console.log(isAppInstalled)}

                {!isAppInstalled && <ButtonComponent className="install-app" buttonImage="assets/images/download.png" callback={onPromptHandlar}/>}
                {!isLogin && <ButtonComponent className="login-button" buttonText="Login" callback={onLoginClicked}/>}
                {isLogin && <WalletIcon onClick={onWalletClick}/>}
            </div>
        </div>
    )   
    
}

const onWalletClick = () => {
    
}





export default Header;