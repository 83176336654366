import React from 'react'

export default function Notification() {
  return (
    <div className='notification-page'>
      <div className='notification-container'>
          <img src='assets/images/Notification.jpg'/>
        <div className='notification-content'>
            <div className='title'>No Notification Found!</div>
        </div>
      </div>
        
    </div>
  )
}
