import React, {Component} from "react";
import LoginPage from "./Components/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LandingPage from "./View/LandingPage";

if (module.hot)
  module.hot.accept()

export default class App extends Component{

    render(){
        return (
            <div className="root">
                <BrowserRouter>                
                    <Routes> 
                            <Route exact path='*' Component={ LandingPage } />
                            <Route exact path='/login' Component={ LoginPage } />
                    </Routes>
                  
                </BrowserRouter>
    
                </div>
            )
    }
    
}
