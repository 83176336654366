export const GamesData = {
    "games": [
        {
            "id": 1,
            "name": "Ludo-classic",
            "range": "5-5000",
        },
        {
            "id": 2,
            "name": "Ludo-classic",
            "range": "500-15000",
        },
        {
            "id": 3,
            "name": "Ludo-Classic",
            "range": "5000-25000",
        },
        {
            "id": 4,
            "name": "Token-Ludo",
            "range": "100-15000",
        },
        {
            "id": 5,
            "name": "Token-Ludo",
            "range": "100-15000",
        }
    ]
}