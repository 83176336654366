import React from 'react'

export default function History(props) {
    
  return (
    <div>{props.data.map((content, index) => {
        return(
           <div className="history-card" key={index}> 
                <div className='history-header'>{content.gameType} </div>
                <div className='history-body'>
                    <div className='history-time'>
                        <span>{content.date}</span>
                        <span>{content.time}</span>
                    </div>

                    {
                        content.gameType !== "Wallet" &&
                        <div className={'history-name'}>
                        
                            <div className={(content.winStaus ? 'win' : 'loose') + ' winStatus'}>
                            {content.winStaus === true ?  "Won" : "Lost" } Against {content.opponentName}
                            </div>
                            <div>{content.roomCode}</div>
                         </div>
                    }

                    {
                        content.gameType === "Wallet" &&
                        <div className={'history-name'}>
                        
                            <div className={'Balance Topup'}>
                            {content.action === "withdrawl" ?  "withdrawl" : "desposit" } Through UPI 
                            </div>
                            <div>{content.UTR}</div>
                         </div>
                         
                    }

                    <div className='history-amount'>
                        <div className={(content.gameType === "Wallet" ? "wallet" : content.winStaus ? 'win' : 'loose') + ' amount'}>
                            {content.gameType === 'Wallet' ? content.topupAmount : `${content.winStaus ? '+' : '-'} (${content.winloose})`}
                        </div>
                        <div className='closing-balance'>closing balance {content.closingBalance}</div>
                    </div>
                </div>
            </div>
        );
    })}</div>
  )
}
