import React from 'react'

export default function OpenBattels(props) {
    const data = props.data;
    return(
        <div className="open-battle-card"> 
            <div className='battle-header'>
               Challenge From- {data.challenger}
            </div>
            <div className='battle-body'>
                <div className='battle-entry-fee'>
                    <span className='entryFee'>Entry Fee</span>
                    <div>
                        <img src='assets/images/wallet.png' />
                        <span>{data.entryFee}</span>
                    </div>
                </div>
                <div className='battle-pool-amount'>
                <span className='entryFee'>Win Amount</span>
                    <div>
                        <img src='assets/images/wallet.png' />
                        <span>{data.pool}</span>
                    </div>
                </div>

                <div className='btnPlay btn btn-success button'>
                    Play
                </div>
            </div>
        </div>
    )
}