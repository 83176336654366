import React from 'react';

const ButtonComponent = (props) => {

    return (
        <div className= {props.className + ' button'} {...props.attribute} onClick={handleClick}> 
            {props.buttonText && props.buttonText}
            {props.buttonImage && <img src={props.buttonImage} />}
        </div>
    )

    function handleClick(){
        props.callback && props.callback();
    }
}

export default ButtonComponent;