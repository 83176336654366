import React, { useEffect, useRef } from "react";
import {Route, Routes } from 'react-router-dom'
import Profile from "./Profile";
import MyWallet from "./MyWallet";
import GameHistory from "./GameHistory";
import TransactionHistory from "./TransactionHistory";
import ReferEarn from "./ReferEarn";
import Notification from "./Notification";
import Support from "./Support";
import GamePlay from "./GamePlay";
import { KYCPending } from "../Components/KYCPending";
import { Legal } from "./Legal";
import { Room } from "./Room";
import { useSelector } from "react-redux";

const ContentRender = () => {

    let contentWrapperRef = useRef();

    useEffect(() => {

        window.addEventListener('resize', onWindowResize)

        onWindowResize();
        
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }

    }, []);

    const onWindowResize = () => {
        let headerHeight = document.querySelector('.Header-Container').getBoundingClientRect().height;
        let footerHeight = document.querySelector('.footer').getBoundingClientRect().height;
        console.log(headerHeight, footerHeight, window.innerHeight);
        let height = window.innerHeight - (headerHeight + footerHeight);
        contentWrapperRef.current.style.height = height + 'px';
    }
    return(
        <div className="ContentWrapper" ref={contentWrapperRef}>
            <div className="inner-wrapper">
                <KYCPending />

                <div className="content">
                    <Routes >
                        <Route exact path='/profile' Component={Profile}/>
                        <Route exact path='/' Component={GamePlay}/>
                        <Route exact path='/mywallet' Component={MyWallet}/>
                        <Route exact path='/game-hisotry' Component={GameHistory}/>
                        <Route exact path='/transaction-history' Component={TransactionHistory}/>
                        <Route exact path='/refer-earn' Component={ReferEarn}/>
                        <Route exact path='/notification' Component={Notification}/>
                        <Route exact path='/support' Component={Support}/>
                        <Route exact path='/legal' Component={Legal}/>
                        <Route exact path='/rooms' Component={Room}/>

                    </Routes >

                </div>
                
            </div>
        </div>
    )
}

export default ContentRender;