const SidebarAction = {
    TOGGLE: 'toggle',
}

const INITIAL_STATE = {
    open: false
}
export const SidebarReducer = (state = INITIAL_STATE, action) => {
   
    switch(action.type){
        case SidebarAction.TOGGLE:
            return{ ...state, open: !state.open  }
            break;

        default:
            return state;
    }

    
}