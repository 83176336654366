import React from 'react'

export default function ReferEarn() {
  return (
    <div className='refer-page'>
      <div className='refer-container'>
          <div className='earnings'>
              <div className='earnings-title title'>Your Referrnal Earnings.</div>
              <div className='refernal-sections'>
                <div className='refer-player-count'>
                    <div className='refer-count-title sub-title'>Referred Players</div>
                    <div className='refer-count'>10</div>
                </div>
                <div className='refer-earnings'>
                    <div className='refer-earnings-title sub-title'>Referral Earnings</div>
                    <div className='refer-count'>100.00</div>
                </div>
              </div>
          </div>

          <div className='referral-code-section'>
            <div className='referral-code-title title'>Referral Code</div>
            <div className='referral-code-body'>
              <div className='referral-ads'>
                <img src=' assets/images/refer.png' />
              </div>
              
              <div className='referral-code'>
                <div className='code'>458620</div>
                <div className='btnCopy button'>Copy</div>
              </div>

              <div className='share-container'>
                <div className='share-on-whatapp button'>
                  <div className='icon'><img src=' assets/images/whatsapp.png'/></div>
                  <div className='text'>Share on Whatsapp</div>
                  </div>

                  <div className='copy-clipboard button'>
                    <div className='icon'><img src=' assets/images/whatsapp.png'/></div>
                    <div className='text'>Copy to Clipboard</div>
                  </div>

              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
