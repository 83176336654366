import React from 'react'
import { Button } from 'react-bootstrap';

export const RoomDetails = (props) => {
    let data = props.rooms;
  return (
    <div className='room-details'>
        <div className='room-details-header'>
            <div className='current-players'>Currently Playing: {data.CurrentlyPlaying}</div>
           
        </div>
        <div className='room-details-body'>
            <div className='room-category'>
                <img src="assets/images/trophy.png" />
                {data.Amount}
            </div>
            <div className='btnPlay button'>
                <Button variant="success">Play</Button>
            </div>
        </div>
        
    </div>
  )
}
